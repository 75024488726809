@import '../../styles/variables';
@import '../../styles/mixins';

.footerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $primary-extra-dark;

  .footer {
    width: 100%;
    padding: 3rem 1.125rem 3em 1.125rem;

    @include bp(m) {
      padding: 3rem 2rem 3em 2rem;
    }

    .footerContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .footerItemContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        gap: 1.25rem;

        @include bp(l) {
          gap: 1rem;
          flex-direction: row;
        }

        .footerItemTitle {
          font-family: $frankRuhlLibreFont;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.5rem;
          margin-bottom: 1rem;
          color: $color-grey-text;

          svg {
            fill: $color-white;
          }
        }

        .footerItem {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          font-family: $frankRuhlLibreFont;
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $bright-color;
          margin-bottom: 0.725rem;

          a {
            display: flex;
            font-family: $frankRuhlLibreFont;
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
            color: $bright-color;
          }
        }

        .newsletterContainer {
          font-family: $frankRuhlLibreFont;
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $bright-color;
          max-width: 23.5rem;

          li {
            margin-bottom: 0.3rem;
          }
        }

        .subscriptionContainer {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          @include bp(l) {
            flex-direction: row;
            gap: 0.5rem;
          }

          .inputContainer {
            width: 100%;

            .emailInput {
              width: 100%;

              :global(.MuiInputLabel-root) {
                width: 100%;
                font-family: $frankRuhlLibreFont;
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                color: $subscribe-input-placeholder-color;
              }

              :global(.MuiInputBase-root) {
                background-color: $subscribe-input-background-color;
              }

              :global(.MuiOutlinedInput-notchedOutline) {
                border: none;
              }

              input::placeholder {
                font-family: $frankRuhlLibreFont;
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                color: $subscribe-input-placeholder-color;
              }
            }
          }

          .subscriptionButton {
            font-family: $frankRuhlLibreFont;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem;
            background-color: $subscribe-button-color;
            height: 3.3208rem;
          }
        }
      }
    }
  }
}

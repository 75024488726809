@import '../../styles/variables';
@import '../../styles/mixins';

.topPart {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0 1.125rem;
  height: 2.125rem;
  background-color: $color-grey-border;
  font-family: $frankRuhlLibreFont;
  color: $text-black;

  @include bp(xs) {
    justify-content: end;
  }

  @include bp(m) {
    padding: 0 2rem;
  }

  > a {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: $text-black;

    img {
      margin: 0 -0.4375rem;
      width: 2.125rem;
      height: 2.125rem;

      @include bp(xs) {
        margin: 0;
      }
    }

    span {
      display: none;
      font-weight: 600;

      @include bp(xs) {
        display: block;
      }
    }

    .icon {
      svg,
      path {
        width: 1.25rem;
        height: 1.25rem;
        stroke: $text-black;
      }

      div {
        display: flex;
      }
    }

    &:hover {
      text-shadow: 0.125rem 0.125rem 0.5rem $color-grey-light;
      color: $primary-main;

      svg,
      path {
        stroke: $primary-main;
      }
    }
  }

  > div {
    font-family: $frankRuhlLibreFont;
    margin: 0 !important;
    color: $text-black;
    font-size: 0.9375rem;
    font-weight: 600;
  }
}

.bottomPart {
  display: flex;
  align-items: center;
  padding: 0 1.125rem;
  z-index: 8;
  background-color: $color-white;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  min-height: 3.6875rem;
  position: sticky;
  top: 0;
  font-family: $frankRuhlLibreFont;
  color: $text-black;
  box-shadow: $primary-button-shadow;

  @include bp(m) {
    padding: 0 2rem;
  }

  .logo {
    svg {
      fill: $main-green;
    }

    div {
      display: flex;
    }
  }

  .navigationContainer {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;

    @include bp(l) {
      gap: 2.5rem;
    }

    a,
    p {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: $text-black;
      transition: text-shadow 0.15s;
      margin: 0;

      @include bp(ml) {
        font-size: 0.9375rem;
      }

      &:hover {
        text-shadow: 0.125rem 0.125rem 0.5rem $color-grey-light;
        color: $primary-light;
      }
    }
  }

  .navigationMenu {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    column-gap: 1.25rem;
    row-gap: 1rem;

    @include bp(l) {
      column-gap: 2.5rem;
    }

    ul {
      list-style: none;
      padding-inline-start: 0rem;
    }

    > li,
    > a,
    > p {
      display: inline-block;
      white-space: nowrap;
    }

    li {
      position: relative;
    }

    ul a,
    ul p {
      display: block;
      text-decoration: none;
      padding: 1.563rem;
      color: $text-black;
      transition: 0.2s;

      :hover {
        text-shadow: 0.125rem 0.125rem 0.25rem $black-color;
      }
    }

    .subMenu {
      display: none;
      position: absolute;
      background-color: $color-white;
      width: 14rem;
      left: -1.563rem;
      padding-top: 1.63rem;
      border-radius: 0.75rem;

      @include bp(m) {
        width: 11rem;
      }

      @include bp(xxxl) {
        width: 12rem;
      }

      li {
        a {
          display: flex;
          justify-content: space-between;

          text-wrap: wrap;
          padding-right: 0.5rem;
        }

        &:last-child {
          border-bottom-left-radius: 0.75rem;
          border-bottom-right-radius: 0.75rem;
        }

        &:hover {
          background-color: $color-grey-light;
        }
      }

      .rightIcon {
        margin-left: 0.5rem;
      }
    }

    .subMenuSecond {
      display: none;
      position: absolute;
      top: 0;
      left: 100%;
      width: 14rem;
      background-color: $color-white;
      border-radius: 0.75rem;
      overflow: hidden;

      @include bp(m) {
        max-width: 9.7rem;

        a {
          text-wrap: wrap;
          padding-right: 0.5rem;
        }
      }

      @include bp(ml) {
        max-width: 10.7rem;
      }

      @include bp(l) {
        max-width: 13.125rem;
      }

      @include bp(xxxl) {
        max-width: 12rem;
      }
    }

    li:hover > .subMenu {
      display: block;
      animation: slideUp 0.2s ease;
    }

    li:hover > .subMenuSecond {
      display: block;
      animation: slideLeft 0.2s ease;
    }

    @keyframes slideUp {
      0% {
        opacity: 0;
        transform: translateY(1.125rem);
      }

      100% {
        opacity: 1;
        transform: none;
      }
    }

    @keyframes slideLeft {
      0% {
        opacity: 0;
        transform: translateX(0.625rem);
      }

      100% {
        opacity: 1;
        transform: none;
      }
    }
  }

  .openSubMenuButton {
    padding: 0.125rem 0.375rem;
    color: $text-black;
    border-radius: 0.313rem;
    margin-left: 0.313rem;

    .openSubMenuIcon {
      font-size: 0.8rem;
    }
  }
}

.mobileMenu {
  top: 3.6875rem;
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 3.6875rem);
  overflow: scroll;
  align-items: center;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  z-index: 8;
  font-family: $frankRuhlLibreFont;
  background-color: $color-white;

  .navigationItem {
    display: flex;
    width: 100%;
    padding: 0.875rem 1.125rem;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    outline: 0;
    border: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    min-width: 4rem;
    border-radius: 0;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-transform: none;
    margin: 0 0 0.25rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    color: $text-black;

    &:hover {
      background-color: $very-light-green;
    }

    &.activeSubItem {
      background-color: $very-light-green;
      font-weight: 600;
    }

    .navigationIcon {
      margin-right: 0.625rem;
    }
  }
}

.mobileNavigationItemContainer {
  width: 100%;

  .navigationItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .mobileNavigationLocationGroupContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      padding-left: 3.375rem !important;
    }
  }

  .mobileNavigationSubMenuTitleContainer {
    width: 100%;
    padding: 0.875rem 1.5rem 0.875rem 2.125rem;
    cursor: pointer;
    user-select: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    display: flex;
    gap: 0.5rem;

    &:hover {
      background-color: $very-light-green;
    }
  }

  .mobileNavigationLocationsWithoutGroupsContainer {
    a {
      padding-left: 2.125rem !important;
    }
  }
}

@import "../../styles/variables";
@import "../../styles/mixins";

.fileFieldContainer {
  display: flex;
  flex-direction: column;

  .fileField {
    background-color: $bright-color;
    transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    text-align: left;
    overflow: hidden;
    display: flex;
    outline: none;

    @include bp(s) {
      flex-direction: row;
    }

    input {
      display: none;
    }

    .fileContainer {
      width: 100%;
      height: 130px;

      @include bp(s) {
        width: 160px;
      }

      .fileSelectedContainer {
        border: 1px dashed $color-grey-light;
        border-radius: 10px;
        padding: 10px;
        height: 100%;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .imageContainer {
          width: 140px;
          height: 110px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }

        .innerFileSelectContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          height: 100%;
          background: rgba(218, 222, 227, 0.5);
          color: $color-grey;

          .innerFileIcon {
            color: $color-grey;

            &.errorFile {
              color: $red-color;
              opacity: 0.8;
            }

            &.successFile {
              color: $primary-main;
              opacity: 0.8;
            }
          }

          .innerFileSelectLabel {
            margin-top: 12px;
            font-family: $globalFont;
            font-size: 11px;
            font-weight: 400;
            color: $color-grey;
          }
        }
      }
    }

    .label {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;

      @include bp(s) {
        padding: 24px;
      }

      h5 {
        margin: 0 0 8px;
        font-weight: 700;
        line-height: 1.5;
        font-size: 14px;
        font-family: $globalFont;
      }

      p {
        margin: 0;
        line-height: 1.57143;
        font-size: 12px;
        font-family: $globalFont;
        font-weight: 400;
        color: rgb(99, 115, 129);

        &.selectedFileLabel {
          margin-top: 20px;
          margin-bottom: 6px;
          color: $color-grey;
          font-size: 11px;

          .selectedFileIcon {
            color: $color-grey;
            margin-right: 6px;
          }
        }
      }

      .fileError {
        font-family: $globalFont;
        font-weight: 400;
        font-size: 10px;
        color: $red-color;
      }
    }
  }

  .multipleImageContainer {
    border: 1px dashed $color-grey-light;
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;

    .imageContainer {
      width: 140px;
      height: 110px;
      position: relative;

      .delete {
        position: absolute;
        right: 0;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 80px;
        height: 24px;
        width: 24px;

        &:hover {
          cursor: pointer;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
}

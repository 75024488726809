@import '../../styles/variables';

.button {
  height: 48px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);

  &:global(.MuiButton-containedPrimary) {
    background-color: $primary-button-background;
    box-shadow: $primary-button-shadow;
    border-radius: 8px;
    padding: 11px 22px;
    font-family: $globalFont;
    text-transform: inherit;
    font-size: 0.9375rem;
    line-height: 1.71429;
    font-weight: 700;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: rgba(145, 158, 171, 0.24);
    }

    &:hover {
      background-color: $primary-button-background-hover;
    }
  }

  &:global(.MuiButton-containedSecondary) {
    background-color: $color-orange;
    box-shadow: $primary-button-shadow;
    border-radius: 8px;
    padding: 11px 22px;
    font-family: $globalFont;
    text-transform: inherit;
    font-size: 0.9375rem;
    line-height: 1.71429;
    font-weight: 700;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: rgba(145, 158, 171, 0.24);
    }

    &:hover {
      background-color: $color-orange-darker;
    }
  }

  &:global(.MuiButton-containedInfo) {
    font-family: $frankRuhlLibreFont;
    font-size: 0.9375rem;
    font-weight: 600;
    background-color: $text-black;
    border-radius: 0.5rem;
    padding: 0.5rem 1.875rem;
    text-transform: none;
    box-shadow: $primary-button-shadow;
    transition: background-color 0.15s;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: rgba(145, 158, 171, 0.24);
    }

    &:hover {
      box-shadow: $primary-button-shadow;
      background-color: $primary-dark;
    }
  }

  &:global(.MuiButton-textPrimary) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.875rem;
    text-transform: inherit;
    font-family: $globalFont;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;
  }

  &.simpleButton {
    height: auto;
  }

  &.saveButton {
    height: auto;
    padding: 6px 48px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.textButton {
    opacity: 0.8;
  }

  &.isActive {
    opacity: 1;
  }

  &.onlyIcon {
    padding-left: 16px;
    padding-right: 16px;
    min-width: 40px;

    :global(.MuiButton-startIcon) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .loadingIcon {
    margin-right: 4px;
  }
}

$poppinsFont: 'Poppins', sans-serif;
$robotoFont: 'Roboto', sans-serif;
$frankRuhlLibreFont: 'Frank Ruhl Libre';
$globalFont: $frankRuhlLibreFont;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #2d5452;
$primary-main: #223d3c;
$primary-dark: #1c3130;
$primary-extra-dark: #1e2423;
$primary-extra-dark-opacity: #1e2423cc;

$main-green: #299439;

$primary-button-background: #2d5452;
$primary-button-background-hover: #223d3c;
$primary-button-shadow: rgba(34, 61, 60, 0.24) 0 8px 16px 0;

$color-white: #ffffff;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-text: #d9d9d9;
$color-grey-icon: #131a29;
$color-grey-border: #ededed;
$color-grey-very-light: #f8f8f8;

$separator-grey: #eaecf0;

$text-black: #1e2423;
$text-tertiary: #475467;
$text-placeholder: #667085;
$text-gray: #343935;

$input-border-light: #d0d5dd;

$black-transparent: rgba(52, 57, 53, 0.7);

$color-orange: #e9471d;
$color-orange-darker: #ee4216;
$color-orange-darkest: #c7350f;

$label: rgb(145, 158, 171);
$label-focused: $primary-light;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$bright-color-transparent: rgba(255, 255, 255, 0.7);
$black-color: #000000;

$red-light: #edacac;
$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-brighter: #efc5bd;

$light-green: #b6d0ad;
$very-light-green: #eef6df;
$very-light-green-transparent: rgba(238, 246, 223, 0.3);

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;
$subscribe-button-color: #ff5a5f;
$program-info-color: #dc2e34;
$subscribe-input-background-color: #f6f6f6;
$subscribe-input-placeholder-color: #1e242380;

$search-input-background-color: #f2f2f2;
$search-input-background-color: #f2f2f2;

$review-box-background-color: #eef6df;

$tag-background-color: #eef6df;
$blog-card-description: #1e242399;
$tag-background-color: #eef6df;
$blog-card-description: #1e242399;

$newsletter-background-color: #f5f5f5;

$charcoal-color: #1a1a1a;
$color-grey-dark: #4f4f4f;
$color-light-grey: #e0e0e0;
$color-pastel-green: #8bd095;
$color-transparent-black-1: rgba(0, 0, 0, 0.1);
$color-transparent-black-3: rgba(0, 0, 0, 0.3);

$summary-shadow: #0000000d;
$payment-summary-color: #4f4f4f;

$city-service-title-color: #1a1a1a;
$privacy-policy-item-color: #1e2423;

$darken-background-color: rgba(0, 0, 0, 0.2);

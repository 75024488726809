@import '../../styles/variables';
@import '../../styles/mixins';

.accommodationContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;

  .valueContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    min-height: 3rem;
    cursor: pointer;
    background-color: $search-input-background-color;
    border-radius: 0.5rem;
    padding: 0 0.75rem;
    width: 100%;

    .value {
      font-family: $frankRuhlLibreFont;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }
}

.popoverContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.625rem;

  .popoverValueContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    height: 2.5rem;

    .peopleIcon {
      color: $light-green;
    }

    .controlContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 9.375rem;

      .countContainer {
        font-family: $frankRuhlLibreFont;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .buttonControl {
        min-width: 1.875rem;
        padding: 0.688rem;
        width: 1.875rem;
        height: 1.875rem;

        &.active {
          background-color: $subscribe-button-color;
        }
      }
    }
  }

  .ageSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.625rem;
  }
}
